<template>
    <Dropdown
        v-model="model"
        :options="cbo"
        :filter="true"
        @filter="filtrarCbo"
        optionLabel="descricao"
        dataKey="id"
    >
    </Dropdown>
</template>
<script>
import BaseService from '../../../services/BaseService';
export default {
    props: {
        value: {
            type: Object
        }
    },
    data() {
        return {
            $service: null,
            cbo: []
        }
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            } 
        }
    },
    async created() {
        this.$service = new BaseService('/cbo');
        const { data } = await this.$service.findAll({ limit: 10 });
        data.items.forEach(item => item.descricao = `${item.codigo} - ${item.descricao}`);
        this.cbo = data.items;
    },
    methods: {
        async filtrarCbo(event) {
            if (event.value) {
                const { data } = await this.$service.findAll({ filter: event.value });
                data.items.forEach(item => item.descricao = `${item.codigo} - ${item.descricao}`);
                this.cbo = data.items;
            }
        }
    }
}
</script>
<style lang="">
    
</style>
